@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Amatic+SC:700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap&subset=latin-ext");

html,
body {
  background-color: white !important;
  font-family: "Open Sans", sans-serif;
}

.title {
  line-height: 40px;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
  &--service {
    margin-bottom: 0px;
    line-height: 40px;
    font-size: 20px;
    margin-left: 63px;
    font-weight: 300;
  }
}

.content {
  p {
    font-size: 16px;
    line-height: 34px;
    margin-bottom: 30px;
  }
}
.cloud {
  width: 100%;
  height: 70vh;
  position: absolute;
  right: 0px;
  text-align: right;
}
.cloud2 {
  width: 100%;
  height: 70vh;
  position: absolute;
  right: 200px;
  text-align: right;
  z-index: 10;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  div {
    &:first-child {
      img {
        height: 50px;
      }
    }
  }
}
.header-menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  li {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
    a {
      font-weight: 400;
      letter-spacing: 1.2px;
      color: white;
      text-decoration: none;
      font-size: 14px;
      &:hover {
        color: #999;
      }
    }
  }
}

.slider {
  &__inner {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-around;
    background-position: bottom;
    align-items: center;
    z-index: 0;
    background-size: cover;
    position: relative;
    span {
      display: flex;
      text-align: center;
      font-family: "Amatic SC", cursive;
      font-size: 65px;
      flex-direction: column;

      span {
        &:nth-child(2) {
          margin-top: 15px;
          font-size: 35px;
          text-align: right;
          margin-right: -50px;
        }
      }
    }
  }
}

.parallax {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.service {
  display: flex;
  min-height: 900px;
  &__holder {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    div {
      &:first-child {
        display: flex;
        align-items: center;
        img {
          width: 60px;
        }
      }
      ul {
        list-style-type: disc;
        margin-top: 10px;

        li {
          display: list-item;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.publishment {
  &__container {
    border: 1px solid #ededed;
    padding: 24px;
    align-items: center;
    text-decoration: none;
    margin-bottom: 24px;
    &:hover {
      background-color: #ededed;
    }
  }
  &__title {
    font-size: 18px;
    color: #2a2a2a;
    text-decoration: none !important;
    margin-bottom: 8px;
  }
  &__date {
    font-size: 13px;
    color: #a3a3a3;
    span {
      font-style: italic;
    }
  }
  &__icon {
    margin-left: 16px;
    font-size: 30px;
    color: #000;
    &:hover {
      color: #2a2a2a;
    }
  }
}

.category-container {
  margin-bottom: 0px !important;
  padding: 0 !important;
  display: block;
  &:nth-child(odd) {
    div {
      padding-left: 30px;
      flex-direction: row-reverse;
      div {
        &:nth-child(1) {
          text-align: right !important;
        }
        &:nth-child(2) {
          text-align: right !important;
        }
        &:nth-child(3) {
          text-align: right !important;
        }
      }
    }
  }
  &:nth-child(even) {
    div {
      padding-right: 30px;
    }
  }
}

.a-btn {
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 46px;
  background-color: transparent;
  border: none;
  color: white;
  -webkit-transition: color 0.3s ease-in, background-color 0.3s ease-in;
  -o-transition: color 0.3s ease-in, background-color 0.3s ease-in;
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.a-btn:after,
.a-btn:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.a-btn:after {
  top: 0;
  left: 0;
}

.a-btn:before {
  bottom: 0;
  right: 0;
}

.a-btn .a-btn-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.a-btn .a-btn-line:after,
.a-btn .a-btn-line:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 50%;
  height: 1px;
  background-color: white;
}

.a-btn .a-btn-line:before {
  left: -11px;
}

.a-btn .a-btn-line:after {
  right: -11px;
}

.a-btn.simple:after,
.a-btn.simple:before {
  border: 1px solid white;
  width: 100%;
  height: 100%;
}

.a-btn.simple .a-btn-line:after,
.a-btn.simple .a-btn-line:before {
  width: 22px;
  -webkit-transition: background-color 0.3s ease-in;
  -o-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

.a-btn.creative:after,
.a-btn.creative:before {
  border: 1px solid transparent;
  width: 0;
  height: 0;
}

.a-btn.creative:after {
  -webkit-transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
  -o-transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
  transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
}

.a-btn.creative:before {
  -webkit-transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
  -o-transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
  transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
}

.a-btn.creative .a-btn-line:after,
.a-btn.creative .a-btn-line:before {
  width: 0;
  -webkit-transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
  -o-transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
  transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
}

.a-btn.creative.anima:after,
.a-btn.creative.anima:before {
  width: 100%;
  height: 100%;
}

.a-btn.creative.anima:after {
  border-top-color: white;
  border-right-color: white;
  -webkit-transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
  -o-transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
  transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
}

.a-btn.creative.anima:before {
  border-bottom-color: white;
  border-left-color: white;
  -webkit-transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
  -o-transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
  transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
}

.a-btn.creative.anima .a-btn-line:after,
.a-btn.creative.anima .a-btn-line:before {
  width: 22px;
  -webkit-transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
  -o-transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
  transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
}

.a-btn:hover,
.a-btn:focus {
  color: #111111;
  background-color: white;
  text-decoration: none;
}

.a-btn:hover .a-btn-line:after,
.a-btn:hover .a-btn-line:before,
.a-btn:focus .a-btn-line:after,
.a-btn:focus .a-btn-line:before {
  background-color: #111111;
}

.a-btn-2 {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 46px;
  background-color: transparent;
  border: none;
  color: #111111;
  -webkit-transition: color 0.3s ease-in, background-color 0.3s ease-in;
  -o-transition: color 0.3s ease-in, background-color 0.3s ease-in;
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.a-btn-2:after,
.a-btn-2:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.a-btn-2:after {
  top: 0;
  left: 0;
}

.a-btn-2:before {
  bottom: 0;
  right: 0;
}

.a-btn-2 .a-btn-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.a-btn-2 .a-btn-line:after,
.a-btn-2 .a-btn-line:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 50%;
  height: 1px;
  background-color: #111111;
}

.a-btn-2 .a-btn-line:before {
  left: -11px;
}

.a-btn-2 .a-btn-line:after {
  right: -11px;
}

.a-btn-2.simple:after,
.a-btn-2.simple:before {
  border: 1px solid #111111;
  width: 100%;
  height: 100%;
}

.a-btn-2.simple .a-btn-line:after,
.a-btn-2.simple .a-btn-line:before {
  width: 22px;
  -webkit-transition: background-color 0.3s ease-in;
  -o-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

.a-btn-2.creative:after,
.a-btn-2.creative:before {
  border: 1px solid transparent;
  width: 0;
  height: 0;
}

.a-btn-2.creative:after {
  -webkit-transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
  -o-transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
  transition: border-color 0s ease-in 1.5s, width 0.3s ease-in 1.2s,
    height 0.3s ease-in 0.9s;
}

.a-btn-2.creative:before {
  -webkit-transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
  -o-transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
  transition: border-color 0s ease-in 0.9s, width 0.3s ease-in 0.6s,
    height 0.3s ease-in 0.3s;
}

.a-btn-2.creative .a-btn-line:after,
.a-btn-2.creative .a-btn-line:before {
  width: 0;
  -webkit-transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
  -o-transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
  transition: width 0.3s ease-in 0s, background-color 0.3s ease-in;
}

.a-btn-2.creative.anima:after,
.a-btn-2.creative.anima:before {
  width: 100%;
  height: 100%;
}

.a-btn-2.creative.anima:after {
  border-top-color: #111111;
  border-right-color: #111111;
  -webkit-transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
  -o-transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
  transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
}

.a-btn-2.creative.anima:before {
  border-bottom-color: #111111;
  border-left-color: #111111;
  -webkit-transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
  -o-transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
  transition: border-color 0s ease-out 0.4s, width 0.3s ease-out 0.6s,
    height 0.3s ease-out 0.9s;
}

.a-btn-2.creative.anima .a-btn-line:after,
.a-btn-2.creative.anima .a-btn-line:before {
  width: 22px;
  -webkit-transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
  -o-transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
  transition: width 0.3s ease-out 1.2s, background-color 0.3s ease-in;
}

.a-btn-2:hover,
.a-btn-2:focus {
  color: white;
  background-color: #111111;
  text-decoration: none;
  outline-color: transparent;
}

.a-btn-2:hover .a-btn-line:after,
.a-btn-2:hover .a-btn-line:before,
.a-btn-2:focus .a-btn-line:after,
.a-btn-2:focus .a-btn-line:before {
  background-color: white;
}

.a-btn-arrow {
  position: relative;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 500;
  text-decoration: none;
  padding-right: 50px;
  color: white;
}

.a-btn-arrow .arrow-right {
  background-color: white;
}

.a-btn-arrow .arrow-right:before,
.a-btn-arrow .arrow-right:after {
  background-color: white;
}

.a-btn-arrow:hover,
.a-btn-arrow:focus {
  text-decoration: none;
  outline-color: transparent;
  color: white;
}

.a-btn-arrow:hover .arrow-right,
.a-btn-arrow:focus .arrow-right {
  right: 0;
}

.a-btn-arrow-2 {
  position: relative;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 500;
  text-decoration: none;
  padding-right: 50px;
  color: #111111;
}

.a-btn-arrow-2 .arrow-right {
  background-color: #111111;
}

.a-btn-arrow-2 .arrow-right:before,
.a-btn-arrow-2 .arrow-right:after {
  background-color: #111111;
}

.a-btn-arrow-2:hover,
.a-btn-arrow-2:focus {
  text-decoration: none;
  outline-color: transparent;
  color: #111111;
}

.a-btn-arrow-2:hover .arrow-right,
.a-btn-arrow-2:focus .arrow-right {
  right: 0;
}

.arrow-right {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 10px;
  width: 31px;
  height: 1px;
  -webkit-transition: right 0.3s ease-in;
  -o-transition: right 0.3s ease-in;
  transition: right 0.3s ease-in;
}

.arrow-right:before,
.arrow-right:after {
  position: absolute;
  display: block;
  right: 0;
  width: 6px;
  height: 1px;
  content: "";
}

.arrow-right:before {
  bottom: 1px;
  -webkit-transform: translateX(0px) translateY(3px) rotate(-45deg);
  -ms-transform: translateX(0px) translateY(3px) rotate(-45deg);
  transform: translateX(0px) translateY(3px) rotate(-45deg);
}

.arrow-right:after {
  top: 1px;
  -webkit-transform: translateX(0px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(0px) translateY(-3px) rotate(45deg);
  transform: translateX(0px) translateY(-3px) rotate(45deg);
}

.arrow-right.grey {
  background-color: #7e7d7d;
}

.arrow-right.grey:before,
.arrow-right.grey:after {
  background-color: #7e7d7d;
}

.blog {
  img {
    max-width: 100% !important;
  }
}

.toggle,
[id^="drop"] {
  display: none;
}

nav {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
* and adding "position:reltive" */
nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

/* Positioning the navigation items inline */
nav ul li {
  margin: 0px;
  display: inline-block;
  border-bottom: 1px solid lighten(#333, 10);
}

/* Styling the links */
nav a {
  display: block;
  padding: 5px 20px;
  color: #2a2a2a;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

nav ul li ul li:hover {
  background: #000000;
}

/* Background color change on Hover */
nav a:hover {
  background-color: #000000;
}

/* Hide Dropdowns by Default
* and giving it a position of absolute */
nav ul ul {
  display: none;
  position: absolute;
  /* has to be the same number as the "line-height" of "nav a" */
  top: 60px;
}

/* Display Dropdowns on Hover */
nav ul li:hover > ul {
  display: inherit;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}

/* Second, Third and more Tiers	
* We move the 2nd and 3rd etc tier dropdowns to the left
* by the amount of the width of the first tier.
*/
nav ul ul ul li {
  position: relative;
  top: -60px;
  /* has to be the same number as the "width" of "nav ul ul li" */
  left: 170px;
}

/* Change ' +' in order to change the Dropdown symbol */
li > a:after {
  content: " +";
}
li > a:only-child:after {
  content: "";
}

#logo {
  display: block;
  padding: 0;
  width: 100%;
  text-align: center;
  float: none;
}

nav {
  margin: 0;
}

/* Hide the navigation menu by default */
/* Also hide the  */
.toggle + a,
.menu {
  display: none;
}

/* Stylinf the toggle lable */
.toggle {
  display: block;
  position: relative;
  background-color: transparent;
  padding: 5px 20px;
  color: white;
  font-size: 14px;
  text-decoration: none;
  border: none;
  &::after {
    content: "☰";
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
    font-size: 25px;
    height: 20px;
  }
}

.toggle:hover {
  background-color: #000;
  color: #ffffff;
}

/* Display Dropdown when clicked on Parent Lable */
[id^="drop"]:checked + ul {
  display: block;
}

nav .menu {
  width: 100%;
}
/* Change menu item's width to 100% */
nav ul li {
  display: block;
  width: 100%;
  background: #000;
  a {
    width: 98%;
    color: #f4f4f4;
    font-size: 13px;
    padding: 10px 1%;
  }
}

nav a:hover,
nav ul ul ul a {
  background-color: #333;
  color: #ccc;
}

nav ul li ul li .toggle,
nav ul ul a,
nav ul ul ul a {
  padding: 5px 20px;
  color: #fff;
  font-size: 11px;
}

nav ul li ul li .toggle,
nav ul ul a {
  background-color: #c7c7c7;
  color: #525252;
}

/* Hide Dropdowns by Default */
nav ul ul {
  float: none;
  position: static;
  color: #ffffff;
  /* has to be the same number as the "line-height" of "nav a" */
}

/* Hide menus on hover */
nav ul ul li:hover > ul,
nav ul li:hover > ul {
  display: none;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  display: block;
  width: 100%;
}

nav ul ul ul li {
  position: static;
  /* has to be the same number as the "width" of "nav ul ul li" */
}

@media all and (max-width: 330px) {
  nav ul li {
    display: block;
    width: 94%;
  }
}
